
import Vue, { PropType } from 'vue'
import ListHeaderWrapper, {
  ListHeaderWrapperProps,
} from '~/components/_general/ListHeaderWrapper.vue'
import FilterSermonsElement, {
  ReduceFilterCategories,
} from '~/components/filter/SermonsElement.vue'
import { SermonSortOptions } from '~/apiclient/apisermons'
import SortSermonsElement, {
  SermonQuery,
} from '~/components/sort/SermonsElement.vue'
import {
  SermonFilterCategories,
  SermonFilterSelection,
} from '~/assets/ts/utils/params'

export default Vue.extend({
  name: 'SiteSermonListHeader',
  components: {
    ListHeaderWrapper,
    FilterSermonsElement,
    SortSermonsElement,
  },
  props: {
    ...ListHeaderWrapperProps,
    sort: {
      type: String as PropType<SermonSortOptions>,
      default: SermonSortOptions.Newest,
    },
    disabledFilterCategories: {
      type: Array as PropType<SermonFilterCategories[]>,
      default: () => [],
    },
    filters: {
      type: Array as PropType<SermonFilterSelection[]>,
      default: () => [],
    },
  },
  computed: {
    SermonQuery() {
      return SermonQuery
    },
    categories(): SermonFilterCategories[] {
      return ReduceFilterCategories([
        SermonFilterCategories.Chapter,
        SermonFilterCategories.Search,
        ...this.specialCases,
        ...this.disabledFilterCategories,
      ])
    },
    specialCases(): SermonFilterCategories[] {
      const specialCases = [] as SermonFilterCategories[]
      const categories = [
        ...this.filters?.map((f) => f.category),
        ...this.disabledFilterCategories,
      ]
      categories.forEach((c) => {
        if (c === SermonFilterCategories.Broadcaster) {
          specialCases.push(SermonFilterCategories.Denomination)
        }
      })
      return specialCases
    },
  },
  methods: {
    setFilters(filters: SermonFilterSelection[]) {
      this.$emit('filter', filters)
    },
    sortChanged(sort: SermonSortOptions) {
      this.$emit('sort', sort)
    },
  },
})
