
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { getStrValuesFromEnum } from '~/assets/ts/enums'

import FancyDropdown, {
  FancyDropdownOption,
} from '~/components/_general/FancyDropdown.vue'
import { waitOneFrame } from '~/assets/ts/utils/misc'
import {
  SermonFilterCategories,
  SermonFilterSelection,
} from '~/assets/ts/utils/params'
import SearchInput from '~/components/_general/SearchInput.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import FilterSermonValueDropdown from '~/components/filter/SermonValueDropdown.vue'
import SaIcon from '~/components/_general/SaIcon.vue'

export function AllSermonFilterCategories(): SermonFilterCategories[] {
  return getStrValuesFromEnum(
    SermonFilterCategories
  ) as SermonFilterCategories[]
}

export function ReduceFilterCategories(remove: SermonFilterCategories[] = []) {
  return AllSermonFilterCategories().filter((c) => !remove.includes(c))
}

export function DefaultFilterCategories() {
  return ReduceFilterCategories([
    SermonFilterCategories.Chapter,
    SermonFilterCategories.Search,
  ])
}

export default Vue.extend({
  name: 'FilterSermonsElement',
  components: {
    SaIcon,
    FilterSermonValueDropdown,
    FancyDropdown,
    InlineIcon,
    SearchInput,
  },
  props: {
    filters: {
      type: Array as PropType<SermonFilterSelection[]>,
      default: () => [],
    },
    categories: {
      type: Array as PropType<SermonFilterCategories[]>,
      default: () => DefaultFilterCategories(),
    },
    disableSearch: {
      type: Boolean,
    },
  },
  data() {
    const searchKeyword = this.$route.query[SermonFilterCategories.Search]
    return {
      selectedCategory: undefined as SermonFilterCategories | undefined,
      selectedFilterValue: undefined as SermonFilterSelection | undefined,
      // change this to false if we want to require using the "Apply" button instead
      autoApply: true,
      searchQuery: (searchKeyword ?? '') as string,
      searchMinLength: 3,
    }
  },
  computed: {
    broadcasterID(): string | undefined {
      const broadcaster = this.filters?.filter(
        (f) => f.category === SermonFilterCategories.Broadcaster
      )
      if (!broadcaster || !broadcaster.length) return undefined
      return broadcaster[0].value as string
    },
    selectedCategoryTitle(): TranslateResult {
      return this.selectedCategory
        ? this.getDisplayTitle(this.selectedCategory)
        : ''
    },
    options(): FancyDropdownOption[] {
      return this.availableFilters.map((f) => {
        return {
          value: f,
          title: this.getDisplayTitle(f),
        }
      })
    },
    availableFilters(): SermonFilterCategories[] {
      const selected = this.selectedCategory
      const categories = this.categories || []
      if (selected && !categories.includes(selected)) {
        return [...categories, selected]
      }
      return categories
    },
    applyDisabled(): boolean {
      return !this.selectedFilterValue
    },
    addNewDisabled(): boolean {
      return !this.availableFilters.length
    },
    showAddNew(): boolean {
      return this.selectedCategory === undefined
    },
  },
  methods: {
    search() {
      const currentSearch = this.filters?.find(
        (f) => f.category === SermonFilterCategories.Search
      )
      if (currentSearch && currentSearch.value === this.searchQuery) return
      const filters = [
        ...this.filtersSansCategory(SermonFilterCategories.Search),
      ] as SermonFilterSelection[]
      if (this.searchQuery) {
        filters.push({
          value: this.searchQuery,
          category: SermonFilterCategories.Search,
        })
      }
      this.changed(filters)
    },
    async preselectFilter(filter: SermonFilterSelection) {
      if (filter.category === SermonFilterCategories.Search) {
        const search = this.$refs.search as Vue
        search.$emit('focus')
        return
      }
      let chapter = false
      if (filter.category === SermonFilterCategories.Chapter) {
        filter = this.filters?.find(
          (f) => f.category === SermonFilterCategories.Book
        ) as SermonFilterSelection
        chapter = true
      }
      this.selectedCategory = filter.category
      this.selectedFilterValue = {
        value: filter.value,
        category: filter.category,
        display: filter.display,
      } as SermonFilterSelection
      await waitOneFrame()
      this.emitToValueDropdown(chapter ? 'openChapter' : 'open')
    },
    emitToValueDropdown(event: 'open' | 'openChapter' | string) {
      const dropdown = this.$refs.valueDropdown as Vue
      dropdown.$emit(event)
    },
    clearSelectedFilter() {
      this.selectedCategory = undefined
      this.selectedFilterValue = undefined
    },
    removeFilter(filter: SermonFilterSelection) {
      let categories = this.filtersSansCategory(filter.category)
      if (filter.category === SermonFilterCategories.Book) {
        categories = categories.filter(
          (f) => f.category !== SermonFilterCategories.Chapter
        )
      }
      this.changed(categories)
      this.clearSelectedFilter()
      if (filter.category === SermonFilterCategories.Search) {
        this.searchQuery = ''
      }
    },
    changed(filters: SermonFilterSelection[]) {
      this.$emit('change', filters)
    },
    addNewFilter() {
      if (this.addNewDisabled) return
      this.selectedCategory = this.availableFilters[0]
    },
    filtersSansCategory(category: SermonFilterCategories) {
      return this.filters?.filter((f) => f.category !== category)
    },
    applyFilter() {
      if (!this.selectedFilterValue) return
      const filters = [
        ...this.filtersSansCategory(this.selectedFilterValue.category),
      ]
      if (this.selectedFilterValue.value) {
        filters.push(this.selectedFilterValue)
      }
      this.changed(filters)
      this.clearSelectedFilter()
    },
    changeFilterValue(filter: SermonFilterSelection, dontApply = false) {
      this.selectedFilterValue = filter
      if (this.autoApply && !dontApply) {
        this.applyFilter()
      }
    },
    getDisplayTitle(value: string): TranslateResult {
      switch (value as SermonFilterCategories) {
        case SermonFilterCategories.Broadcaster:
          return this.$t('Broadcaster')
        case SermonFilterCategories.Event:
          return this.$t('Category')
        case SermonFilterCategories.Language:
          return this.$t('Language')
        case SermonFilterCategories.Denomination:
          return this.$t('Denomination')
        case SermonFilterCategories.Series:
          return this.$t('Series')
        case SermonFilterCategories.Speaker:
          return this.$t('Speaker')
        case SermonFilterCategories.Year:
          return this.$t('Year')
        case SermonFilterCategories.Book:
          return this.$t('Scripture')
        case SermonFilterCategories.Chapter:
          return this.$t('Chapter')
        case SermonFilterCategories.Search:
          return this.$t('Search')
        default:
          return value
      }
    },
  },
})
